<template>
  <div class="campaign">
    <Module-Header title="Campaign"></Module-Header>

    <div class="cp">
      <div class="tip-title">Campaign Period</div>
      <div class="card">
        <div class="single">
          <div class="description">“Plastic-Free Rewards Scheme” Campaign Period</div>
          <span class="value">{{ info.campaign.campaignDate }}</span>
        </div>

        <div class="single">
          <div class="description">"Plastic-Free Rewards Scheme" Reward Redemption Period:</div>
          <span class="value">{{ info.campaign.rewardRedemptionDate }}</span>
        </div>

        <div class="single">
          <div class="description">"Plastic-Free Rewards Scheme" G$ and Reusable Cutlery Gift Set Voucher Redemption Period:</div>
          <span class="value">{{ info.campaign.greenAndCutleryRedemptionDate }}</span>
        </div>
      </div>
    </div>

    <div class="sr" v-if="false">
      <div class="tip-title">Stamps Rules</div>
      <div class="card">
        <div class="single">
          <div class="description">Number of stamps obtainable per member per month:</div>
          <span class="value">{{ info.campaign.cafeDialogueumNumberOfStampsObtainable }}</span>
        </div>

        <div class="single">
          <div class="description">Number of Plastic-Free actions per member per day for each disposable-free action:</div>
          <span class="value">{{ info.campaign.cafeDialogueumNumberOfPlasticFreeActions }}</span>
        </div>
      </div>
    </div>

    <div class="cs">
      <div class="tip-title">Campaign Statistics</div>
      <div class="card">
        <div class="single" v-for="(its, idx) in campaign" :key="idx">
          <div class="description">{{ its.label }}</div>
          <el-button class="export" @click="clickExport(its.label)"><img src="@/assets/images/link.png" />Export</el-button>
        </div>

        <!-- <div class="single">
          <div class="description">G$ Exchange</div>
          <el-button class="export" @click="exportGExchange"><img src="@/assets/images/link.png" />Export</el-button>
        </div> -->
      </div>
    </div>

    <ChioceTime @close="chioceTimeSwitch = false"
      @export="exportMulti" v-if="chioceTimeSwitch"></ChioceTime>

    <loadingDialog :center-dialog-visible="centerDialogVisible"></loadingDialog>
    
  </div>
</template>

<script>
import { getSetting } from '@/api/setting'
import exportMulti from './exportCsv'
import ChioceTime from './components/chioceTime.vue'
import loadingDialog from "@/components/loadingDialog/index";

export default {
  components: {
    ChioceTime,
    loadingDialog
  },
  data() {
    return {
      monthEn: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

      campaign: [
        {label: 'Eatery', },
        {label: 'Eatery Group', },
        {label: 'App Member', },
        {label: 'Earn Stamps', },
        {label: 'Redeem Reward', },
        {label: 'Earn and Redeem Stamps', },
        {label: 'GDollar Exchange', },
        {label: 'Campaign statistics', },
        {label: 'GDollar statistics', },
      ],
      info: {
        "campaign": {}
      },

      exportLabel: '',
      chioceTimeSwitch: false,
      centerDialogVisible: false
    }
  },
  methods: { 
    exportMulti(params) {
      this.centerDialogVisible = true
      exportMulti(this.exportLabel, params, this, () => {
        this.centerDialogVisible = false
      })
    },
    clickExport(label) {
      this.exportLabel = label
      this.chioceTimeSwitch = true
    },
    // 转化日期 '2024-04-30' -> '30th Apr,2024'
    convertDate(date = '2024-04-30') {
      let [year, month, day] = date.split('-')
      day = day + 'th'
      month = this.monthEn[Number(month) - 1]

      return `${day} ${month},${year}`
    },
    getData() {
      getSetting().then((v) => {
        let its = v.data.campaign
        its.campaignDate = this.convertDate(its.campaignStartDate) + ' - ' + this.convertDate(its.campaignEndDate)
        its.rewardRedemptionDate = this.convertDate(its.rewardRedemptionStartDate) + ' - ' + this.convertDate(its.rewardRedemptionEndDate)
        its.greenAndCutleryRedemptionDate = this.convertDate(its.greenAndCutleryRedemptionStartDate) + ' - ' + this.convertDate(its.greenAndCutleryRedemptionEndDate)
        this.info = v.data
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.campaign {
  .sr, .cs, .cp {
    margin-top: 2.5rem;
    color: #1B7379;
    font-weight: 700;

    .tip-title {
      margin-bottom: .9375rem;
      font-size: 1.5rem;
    }

    .card {
      width: 100%;
      padding: 1.25rem 1.25rem 0 1.25rem;
      box-sizing: border-box;
      border: 1px solid #1B7379;
      border-radius: 1.25rem;

      .single {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .description {
          color: #000;
          font-size: 1.125rem;
          font-weight: 700;
        }

        .value {
          color: #000;
          font-size: 1.125rem;
          margin-left: .625rem;
          font-weight: 400;
        }
      }
    }
  }

  .sr .description {
    width: 800px;
  }

  .cs .description {
    width: 18.75rem;
  }
  .cp .description {
    width: 53.125rem;
  }
}
</style>