<template>
  <div class="chioce-time" @click.prevent="close">
    <div class="card" @click.stop="() => false">
      <div class="header">App Member</div>
      <div class="date">
        <Date-picker :joiningDate="start" @updateDate="changeDateStart"></Date-picker>
        <span>to</span>
        <Date-picker :joiningDate="end" @updateDate="changeDateEnd"></Date-picker>
      </div>
      <div class="export">
        <el-button class="export" @click="clickExport()"><img src="@/assets/images/link.png" />Export</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let curDate = new Date().getTime(),
    prevMonth = curDate - 3600 * 24 * 30 * 1000,
    end = new Date(curDate).format('dd/MM/yyyy'),
    start = new Date(prevMonth).format('dd/MM/yyyy')
    console.log()
    return {
      start,
      end,
    }
  },
  methods: {
    changeDateStart(s) {
      this.start = s ? new Date(s).format('dd/MM/yyyy') : ''
    },
    changeDateEnd(s) {
      this.end = s ? new Date(s).format('dd/MM/yyyy') : ''
    },

    clickExport() {
      this.centerDialogVisible = true
      let params = {
        start_date_gt: this.start,
        end_date_lt: this.end,
      }
      this.$emit('export', params)
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.chioce-time {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px 50px 0;

  .card {
    width: 560px;
    height: 240px;
    border-radius: 5px;
    background-color: #fff;
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;

    & > div {
      width: 100%;
    }

    .header {
      font-weight: 700;
      font-size: 18px;
      color: #333;
    }

    .date {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .export {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>