import http from '@/utils/http'

export function reportEatery(params){
  return http('/eatery_report', {params})
}

export function reportEateryGroup(params){
  return http('/eatery_group_report', {params})
}

export function reportMember(params){
  return http('/member_report', {params})
}

export function reportEarnStamps(params){
  return http('/earn_stamps_report', {params})
}

export function reportRedeemRewardReport(params){
  return http('/redeem_reward_report', {params})
}

export function reportEarnAndRedeemRewardReport(params){
  return http('/earn_and_redeem_reward_report', {params})
}

export function reportGdollarExchangeReport(params){
  return http('/gdollar_exchange_report', {params})
}

export function reportCampaignStatisticReport(params){
  return http('/campaign_statistic_report', {params})
}

export function reportGdollarStatisticReport(params){
  return http('/gdollar_statistic_report', {params})
}