import { 
  reportEatery, 
  reportEateryGroup, 
  reportMember,
  reportEarnStamps,
  reportRedeemRewardReport,
  reportEarnAndRedeemRewardReport,
  reportGdollarExchangeReport,
  reportCampaignStatisticReport,
  reportGdollarStatisticReport
 } from '@/api/report'

let vue = null

export default function exportMulti(label, params, v, callback) {
  vue = v

  params = {
    end_date_lt: convertTime(params.end_date_lt),
    start_date_gt: convertTime(params.start_date_gt)
  }
  console.log(params)

  switch(label) {
    case 'Eatery':
      reportEatery(params).then(v => {
        let data = v.data
        exportCsv(data, label)
      }).finally(callback); break
    case 'Eatery Group':
      reportEateryGroup(params).then(v => {
        let data = v.data
        exportCsv(data, label)
      }).finally(callback); break
    case 'App Member':
      reportMember(params).then(v => {
        let data = v.data
        exportCsv(data, label)
      }).finally(callback); break
    case 'Earn Stamps':
      reportEarnStamps(params).then(v => {
        let data = v.data
        exportCsv(data, label)
      }).finally(callback); break
    case 'Redeem Reward':
      reportRedeemRewardReport(params).then(v => {
        let data = v.data
        exportCsv(data, label)
      }).finally(callback); break
    case 'Earn and Redeem Stamps':
      reportEarnAndRedeemRewardReport(params).then(v => {
        let data = v.data
        exportCsv(data, label)
      }).finally(callback); break
    case 'GDollar Exchange':
      reportGdollarExchangeReport(params).then(v => {
        let data = v.data
        exportCsv(data, label)
      }).finally(callback); break
    case 'Campaign statistics':
      reportCampaignStatisticReport(params).then(v => {
        let data = v.data
        exportCsv(data, label)
      }).finally(callback); break
    case 'GDollar statistics':
      reportGdollarStatisticReport(params).then(v => {
        let data = v.data
        exportCsv(data, label)
      }).finally(callback); break
  }
}

/**
 *  转化日期 '30/04/2024' -> '2024-04-30'
 */
function convertTime(date) {
  let [day, month, year] = date.split('/')
  return `${year}-${month}-${day}`
}


const exportCsv = function(csv, title) {
  console.log(csv)
  const filename = `${title}.csv` // 拼接文件名

  const blob = new Blob([csv])  //创建一个新的 Blob 对象
  const url = window.URL.createObjectURL(blob)  //  把一个blob对象转化为一个Blob URL，创建下载链接
  const downloadLink = document.createElement('a')  // 生成一个a标签
  downloadLink.href = url
  downloadLink.download = filename  // // dowload属性指定下载后文件名
  downloadLink.click() // 点击下载
  vue.chioceTimeSwitch = false
  window.URL.revokeObjectURL(url); // 释放掉blob对象
}